import { useDispatch, useSelector } from "react-redux";

import useMediaQuery from "@mui/material/useMediaQuery";

import "./FeedDetailsTop.css";
import { lowercaseAllButFirstLetter } from "../../../helperFunctions/strings";
import { getIdeaIconElement } from "../../../helperFunctions/getIdeacardIcons";

import { ExpandBox } from "../../Common/ExpandBox";
import {
  selectBook,
  selectPublicBook,
} from "../../../Utils/Features/librarySlice";
import toastMessage from "../../../helperFunctions/toastMessage";
import { Ownership } from "../../Common/Ownership/Ownership";
import { FollowBook } from "../../Book/FollowBook";
import { History } from "../../../helperFunctions/global";

const FeedDetailsTop = ({ feedDataList, uniqueKeyId }) => {
  const dispatch = useDispatch();
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { userId: currentUserId } = useSelector((state) => state.auth);

  const { userId } = useSelector((state) => state.auth);
  const showFollowBook =
    Array.isArray(feedDataList.user) && feedDataList.user[0]?._id !== userId;

  const navigateToBook = (data, bookId) => {
    const libraryBook = data?.user[0]?._id === currentUserId;

    if (!libraryBook) {
      const isPublic = data?.book[0]?.public;
      if (!isPublic) {
        toastMessage("error", 2000, "This book is not public.");
        return;
      } else {
        dispatch(
          selectPublicBook({
            bookId: data?.book[0]?._id,
          })
        );
      }
    } else {
      dispatch(selectBook(data?.book[0]?._id));
    }

    localStorage.setItem("feedCurrentBookId", bookId);

    History.deepNavigate("/views");
    // TODO call the sync for highlights event towards our extension
  };

  return (
    <div
      className={`titleStyle relative grid grid-cols-[40px_auto_auto_auto_auto_10px] ${
        isSmDown ? "" : "pr-[44px]"
      }`}
    >
      {feedDataList?.book?.length > 0 && (
        <>
          {/** dispay: contents -style makes the div act as strictly a placeholder for the content HTML 
          and prevents this div from affecting the grid layout as we need the two first lines to jump to book 
          but third (title) line toggle the title (length) in mobile view not be affected */}
          <div
            style={{ display: "contents" }}
            onClick={() => navigateToBook(feedDataList, uniqueKeyId)}
            className="cursor-pointer"
          >
            <div className="imgWrapper row-span-2">
              <img
                src={feedDataList?.book[0]?.img_path}
                alt={feedDataList?.book[0]?.title}
                className="imageStyle"
              />
            </div>

            {/** First row: book title and author */}
            <div className="truncate col-span-3">
              {feedDataList?.book[0]?.title && feedDataList.book[0].title}
            </div>

            <div className="dotsWrapper truncate">
              {feedDataList?.book[0]?.author &&
                feedDataList?.book[0]?.author.split(",").reverse().join(" ")}
            </div>
            <div>
              {showFollowBook && (
                <FollowBook bookData={feedDataList?.book[0]} />
              )}
            </div>

            {/** Second row: feed type (ideacard/highlight), ownership and tags */}
            <div className="truncate col-span-5">
              {(feedDataList.type === "idea" ||
                feedDataList.type === "suggestion") && (
                <>
                  {getIdeaIconElement(feedDataList?.label_id)}

                  <span className="pl-[8px]">
                    {lowercaseAllButFirstLetter(
                      feedDataList?.label_label
                    )?.slice(0, -1)}
                  </span>
                </>
              )}

              {feedDataList.type === "highlight" && (
                <span className="text-nowrap align-middle inline-block w-[20px] h-[20px] rounded-[50%] bg-white border-[6px] border-[#c2c2c2] mb-[2px] mr-[8px]"></span>
              )}
              {feedDataList.type === "highlight" && <span>Highlights</span>}

              <span className="dotsWrapper bottom-line">
                <Ownership
                  ownership={feedDataList?.ownership}
                  bigStyle={true}
                  narrowStyle={
                    isSmDown &&
                    feedDataList?.tags &&
                    feedDataList?.tags.length > 0
                  }
                />
              </span>
              {feedDataList?.tags && feedDataList?.tags.length > 0 && (
                <span className="dotsWrapper bottom-line">
                  {feedDataList?.tags?.length > 0 ? "#" : ""}{" "}
                  {feedDataList?.tags?.join("  #")}
                </span>
              )}
            </div>
          </div>
        </>
      )}
      {/** Third row: ideacard title */}
      <div className="itemTitleStyle col-span-6">
        {feedDataList?.context && feedDataList?.context}
        {/* shows 3 lines for mobile and 4 lines for desktop: */}
        {feedDataList?.title && (
          <ExpandBox collapsedHeight={isSmDown ? 100 : 150}>
            {feedDataList?.title}
          </ExpandBox>
        )}
      </div>
    </div>
  );
};

export default FeedDetailsTop;
