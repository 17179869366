import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiStatus } from "./ApiStatus";
import { getApiUrl } from "../../helperFunctions/envVars";
import { createCheckoutSession } from "../Payment/paymentSlice";
import { getHeaders, subscriptionChecked } from "./authSlice";

const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

export const fetchProfile = createAsyncThunk("/profile/getInfo", async () => {
  const response = await axios.get(`${apiRoot}/user/profile`, getHeaders());
  return response.data.data;
});

export const putProfileData = createAsyncThunk(
  "/profile/putInfo",
  async ({ _, data }) => {
    await axios.put(`${apiRoot}/user/profile/update`, data, getHeaders());
    return data;
  }
);

export const getUserEmail = createAsyncThunk("/user/getEmail", async () => {
  const response = await axios.get(`${apiRoot}/user/`, getHeaders());
  return response.data;
});

export const fetchStats = createAsyncThunk("/user/stats", async () => {
  const response = await axios.get(`${apiRoot}/user/stats`, getHeaders());
  return response.data.data;
});

export const deleteAccount = createAsyncThunk(
  "/user/account-delete",
  async ({ reason, otherReason, unhappyReason }) => {
    const response = await axios.post(
      `${apiRoot}/user/account-delete`,
      {
        ...(reason != null && { reason }),
        ...(otherReason != null && { otherReason }),
        ...(unhappyReason != null && { unhappyReason }),
      },
      getHeaders()
    );
    return response.data.data;
  }
);

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState: {
    profileData: {
      username: "",
      firstname: "",
      alternativeEmail: "",
      profileImageUrl: "",
      public: true,
    },
    profileStatus: null,
    profileError: null,
    setProfileDataStatus: null,
    setProfileDataError: null,
    userEmail: "",
    stats: {},
    statsStatus: null,
    deleteStatus: null,
    deleteReason: null,
  },

  reducers: {
    setTokens: (state, action) => {
      state.profileData.tokensAvailable = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchProfile.pending, (state, action) => {
        state.profileStatus = ApiStatus.Pending;
        state.profileError = null;
        if (!state.profileData) state.profileData = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.profileStatus = ApiStatus.Fulfilled;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.profileStatus = ApiStatus.Rejected;
        state.profileError = action.error.message;
      })
      .addCase(putProfileData.pending, (state, action) => {
        state.setProfileDataStatus = ApiStatus.Pending;
        state.setProfileDataError = null;
      })
      .addCase(putProfileData.fulfilled, (state, action) => {
        // update slice since we are not refetching profile data at each render anymore
        state.profileData = { ...state.profileData, ...action.payload };
        state.setProfileDataStatus = ApiStatus.Fulfilled;
      })
      .addCase(putProfileData.rejected, (state, action) => {
        state.setProfileDataStatus = ApiStatus.Rejected;
        state.setProfileDataError = action.error.message;
      })
      .addCase(getUserEmail.fulfilled, (state, action) => {
        state.setProfileDataStatus = ApiStatus.Fulfilled;
        state.userEmail = action?.payload?.data[0]?.email;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        // check that we are getting updated profile data as result,
        // otherwise just mark profile to be reloaded
        if (action.payload.data.profile?.subscriptionType) {
          state.profileData = action.payload.data.profile;
        } else {
          state.profileStatus = ApiStatus.NotRun;
        }
      })
      .addCase(fetchStats.pending, (state, action) => {
        state.statsStatus = ApiStatus.Pending;
        if (!state.stats) state.stats = null;
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.stats = action.payload;
        state.statsStatus = ApiStatus.Fulfilled;
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.statsStatus = ApiStatus.Rejected;
      })
      .addCase(deleteAccount.pending, (state, action) => {
        state.deleteStatus = ApiStatus.Pending;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.deleteStatus = ApiStatus.Fulfilled;
        state.profileData = action.payload.profile;
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.deleteReason = action.meta.arg;
        state.deleteStatus = ApiStatus.Rejected;
      })
      .addCase(subscriptionChecked, (state, action) => {
        state.profileData.trialStarted = undefined;
        state.profileData.trialEnded = undefined;
        state.profileData.accountDeleted = undefined;
      });
  },
});

// export const {} = profileSlice.actions;
export default profileSlice.reducer;

export const { setTokens } = profileSlice.actions;
