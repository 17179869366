import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Grid from "@mui/material/Grid";
import SquareIcon from "@mui/icons-material/Square";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";

import "../ListView/ListView.css";
import "./TileView.css";
//style component
import {
  CardStructureBook,
  TilesCardStructureBook,
  ChaptersUl,
  ChaptersLi,
} from "../ListView/styled";
import PersistentDrawerRight from "../../components/Drawer/Drawer";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { updateLevelCounter } from "../../Utils/Features/levelCounterSlice";
import {
  CreateIdeaDialog,
  addTextSelection,
  cleanupTextSelection,
} from "../../helperFunctions/useTextSelection";
import {
  getCurrentBook,
  fetchListViewData,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { Loading } from "../../components/Loading";
import { IdeaCard } from "../../components/Views/IdeaCard";
import highlightTester from "../../helperFunctions/highlightTester.js";
import { collectSelectedIdeas } from "../ListViewMenu.jsx";
import Box from "@mui/material/Box";
import { styled, Typography } from "@mui/material";
import { isDevelopment } from "../../helperFunctions/envVars.js";
import { Message } from "../../components/Common/Message";

const CustomAccordion = styled(Accordion)(() => ({
  background: "transparent",
  boxShadow: "none",
}));

const CustomAccordionDetails = styled(AccordionDetails)(() => ({
  padding: "0px",
}));

const CustomAccordionSummary = styled(AccordionSummary)(() => ({
  padding: "0px",
  paddingLeft: "10px",
  "& .MuiAccordionSummary-content": {
    margin: "0px",
    display: "flex",
    width: "calc(100% - 22px)", // sorry for this :´(
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    margin: "0px",
    alignSelf: "flex-start", // Align the expand icon to the top
  },
  display: "flex",
  alignItems: "center", // Align items to the top
}));

export default function TileView({ chatOpen, chatWidth }) {
  let chapterLevel = useSelector(
    (state) => state?.userConfig?.tilesViewFilter?.chapterLevel?.value ?? 1
  );

  const [filteredTileViewData, setFilteredTileViewData] = useState({});
  const [expanded, setExpanded] = useState(false);

  const tileViewData = useSelector((state) => state.library.listViewData);
  const openBook = useSelector((state) => getCurrentBook(state));
  const viewStatus = useSelector((state) => state.library.viewStatus);
  const viewError = useSelector((state) => state.library.viewError);

  const canCreateIdeas = !tileViewData?.read_only; // || tileViewData.followedByMe;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const checkHighlightsRecursive = (sections) => {
    return (
      sections?.every(
        (section) => !section?.highlights || section?.highlights?.length === 0
      ) &&
      sections?.every((section) =>
        section?.entries?.length > 0
          ? checkHighlightsRecursive(section?.entries)
          : true
      )
    );
  };

  // TODO calculate on all chapterLevels -> array[Level]=maxOnThatLevel
  const maxCountIdeaAndHighlight = useMemo(() => {
    if (!tileViewData?.data) return 1;

    let max = 0;
    const processSection = (section) => {
      const total = section.totalHighlightsCount + section.totalIdeacardsCount;
      max = Math.max(max, total);

      if (section.entries) {
        section.entries.forEach(processSection);
      }
    };

    tileViewData.data.forEach(processSection);
    return Math.max(1, max);
  }, [tileViewData]);

  // this will check if highlights is empty
  const allSectionsHaveEmptyHighlights =
    tileViewData && checkHighlightsRecursive(tileViewData?.data);

  const [maxCount, setMaxCount] = useState(1);
  let levelCount = 1;
  const dispatch = useDispatch();

  const [createIdeaCardDialogOpen, setCreateIdeaCardDialogOpen] =
    useState(false);
  const textSelectableContentRef = useRef(null);
  const { tilesViewFilter: filter } = useSelector((state) => state.userConfig);

  // Define getGradientBackground inside component to access current maxCount
  const getGradientBackground = useCallback(
    (highlightsCount, ideaCardsCount) => {
      const totalChapter = highlightsCount + ideaCardsCount;
      const opacity = Math.min(1, totalChapter / maxCountIdeaAndHighlight);
      return `linear-gradient(90deg, var(--cardBackgroundColor) 93%, rgba(var(--primaryColorGradient), ${opacity}) 100%)`;
    },
    [maxCountIdeaAndHighlight]
  ); // Depend on maxCount

  useEffect(() => {
    const liChilds = document.querySelectorAll(".highlightLi");

    if (highlightTester()) {
      for (var i = 0; i < liChilds.length; ++i) {
        let item = liChilds[i].classList;
        if (item) {
          if (filter?.showHighlights) {
            liChilds[i].classList.remove("d-none");
          } else {
            liChilds[i].classList.add("d-none");
          }
        }
      }
    }
  }, [filter?.showHighlights, filteredTileViewData]);

  useEffect(() => {
    if (filter?.showIdeacards)
      collectSelectedIdeas(Object.values(filter.showIdeacards));
  }, [filter?.showIdeacards, filteredTileViewData]);

  const callForLevelCounter = (levelCounter) => {
    if (levelCounter > maxCount) {
      setMaxCount(levelCounter);
    }
  };

  const dataIterator = (data) => {
    const iteratedData = [];
    data?.forEach((item) => {
      if (item.entries?.length) {
        item.entries.forEach((data) => {
          iteratedData.push(data);
        });
      }
    });
    return iteratedData;
  };

  const dataFilterByLevel = (levelCountGlobal) => {
    if (levelCountGlobal === 1) {
      setFilteredTileViewData(tileViewData?.data);
    }
    let filteredData = [];
    if (levelCountGlobal === 2) {
      filteredData = [];
      filteredData = [...dataIterator(tileViewData?.data)];
      setFilteredTileViewData(filteredData);
    }
    if (levelCountGlobal === 3) {
      filteredData = [];
      tileViewData?.data?.forEach((item) => {
        if (item.entries?.length) {
          filteredData = [...filteredData, ...dataIterator(item.entries)];
        }
      });
      setFilteredTileViewData(filteredData);
    }
    if (levelCountGlobal === 4) {
      filteredData = [];
      tileViewData?.data?.forEach((item) => {
        if (item.entries?.length) {
          item.entries.forEach((item) => {
            if (item.entries?.length) {
              filteredData = [...filteredData, ...dataIterator(item.entries)];
            }
          });
        }
      });
      setFilteredTileViewData(filteredData);
    }

    if (levelCountGlobal === 5) {
      filteredData = [];
      tileViewData?.data?.forEach((item) => {
        if (item.entries?.length) {
          item.entries.forEach((item) => {
            if (item.entries?.length) {
              item.entries.forEach((item) => {
                if (item.entries?.length) {
                  filteredData = [
                    ...filteredData,
                    ...dataIterator(item.entries),
                  ];
                }
              });
            }
          });
        }
      });
      setFilteredTileViewData(filteredData);
    }
  };

  const getContentRecursive = (item, levelCount) => {
    callForLevelCounter(levelCount);

    return (
      <>
        {item.entries?.length ? (
          <ChaptersUl className="!mx-0 !border-x-0">
            {item.entries.map((k, i) => (
              <React.Fragment key={i}>
                {getContentRecursive(k, levelCount + 1)}
              </React.Fragment>
            ))}
          </ChaptersUl>
        ) : null}
        {item.highlights?.length ? (
          <ChaptersUl className=" highlightUl !mx-0 !border-x-0">
            {item.highlights.map((highlight, i) => {
              return (
                <div key={`highlight-${highlight._id}-${highlight.position}`}>
                  {highlight.idea_cards?.length
                    ? highlight.idea_cards.map((ideacard, index) => {
                        if (
                          ideacard?.title?.length > 0 &&
                          ideacard?._id &&
                          ideacard?.title?.trim() !== ""
                        ) {
                          return (
                            <IdeaCard
                              ideaCardId={ideacard._id}
                              key={ideacard._id}
                            />
                          );
                        } else return null;
                      })
                    : null}
                  {highlight.context ? (
                    <ChaptersLi
                      key={highlight._id}
                      id={`highlight-${highlight.position}`}
                      className="highlightLi"
                    >
                      <div className="highlightDiv">
                        <SquareIcon fontSize={"small"} className="icon" />
                        <span
                          data-start={highlight.start}
                          data-book_id={highlight.book_id}
                          data-user_id={highlight.user_id}
                          id={highlight._id}
                          className={
                            canCreateIdeas ? "highlightSpan customCursor" : ""
                          }
                          style={{
                            "::selection": { backgroundColor: "#FFDAC1" },
                          }}
                        >
                          {highlight.context}

                          {highlight[isDevelopment() ? "context" : "note"] && (
                            <span className="text-sm notesWrapper ml-2 mt-[4px] cursor-default">
                              <Tooltip
                                title={
                                  highlight[
                                    isDevelopment() ? "context" : "note"
                                  ]
                                }
                                className="text-sm"
                                arrow
                              >
                                <SpeakerNotesIcon
                                  sx={{
                                    color: "var(--fontColor)",
                                    fontSize: "17px",
                                  }}
                                />
                              </Tooltip>
                            </span>
                          )}
                        </span>
                      </div>
                    </ChaptersLi>
                  ) : null}
                </div>
              );
            })}
          </ChaptersUl>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    if (!tileViewData && openBook) {
      dispatch(fetchListViewData(openBook?._id));
    }
  }, [openBook, dispatch, tileViewData]);

  useEffect(() => {
    dispatch(updateLevelCounter(maxCount));
  }, [maxCount, dispatch]);

  useEffect(() => {
    setMaxCount(0);

    if (canCreateIdeas) {
      addTextSelection(textSelectableContentRef, setCreateIdeaCardDialogOpen);
    }

    return () => {
      if (canCreateIdeas) {
        cleanupTextSelection(textSelectableContentRef);
      }
    };
  }, [tileViewData, canCreateIdeas]);

  useEffect(() => {
    dataFilterByLevel(chapterLevel);
    // we want this effect to run only once when tileview data is loaded or levelcountglobal is changed:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterLevel, tileViewData]);

  if (allSectionsHaveEmptyHighlights) {
    return (
      <Message
        message={
          <>
            😞 Sorry, there are no highlights or idea cards available for this
            book. 😞
          </>
        }
        instruction="Highlight your favorite passages and share your insights
        with us"
      />
    );
  }

  return (
    <>
      <div
        className="tileViewWrapper "
        style={{ alignItems: "center", overflow: "hidden auto" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {canCreateIdeas && (
          <CreateIdeaDialog
            open={createIdeaCardDialogOpen}
            handleClose={() => setCreateIdeaCardDialogOpen(false)}
          />
        )}
        {!openBook ? (
          <Message
            message="Oops! You have not selected a book yet!"
            instruction="select a book first, please."
          />
        ) : viewStatus === ApiStatus.Fulfilled ? (
          <>
            <PersistentDrawerRight
              chatOpen={chatOpen}
              chatWidth={chatWidth}
              childrenx={
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    height: "100%",
                  }}
                  className="items-center"
                >
                  <div
                    ref={textSelectableContentRef}
                    className="flex flex-wrap gap-0"
                  >
                    <Grid container spacing={1}>
                      {filteredTileViewData?.length
                        ? filteredTileViewData?.map((item, index) => {
                            const highlightCount = item.totalHighlightsCount;
                            const ideaCardCount = item.totalIdeacardsCount;
                            // console.log(item, h+"/"+highlightCount, i+"/"+ideaCardCount);
                            const backgroundGradient = getGradientBackground(
                              highlightCount,
                              ideaCardCount
                            );

                            return item.highlights?.length ||
                              item.entries?.length ? (
                              <Grid
                                item
                                xs={12}
                                key={`item-${item.nextPosition}+${item.tocPositionId}`}
                              >
                                <TilesCardStructureBook
                                  className="background-gradient-wrapper"
                                  style={{
                                    background: backgroundGradient,
                                  }}
                                >
                                  <CustomAccordion
                                    disableGutters
                                    expanded={expanded === `panel-${index}`}
                                    onChange={handleChange(`panel-${index}`)}
                                  >
                                    <CustomAccordionSummary
                                      expandIcon={
                                        <ExpandMoreIcon
                                          sx={{
                                            paddingRight: "3px",
                                            boxSizing: "content-box", // Ensure the padding doesn't affect the overall size
                                          }}
                                        />
                                      }
                                      aria-controls={`panel-${index}-content`}
                                      id={`panel-${index}-header`}
                                    >
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 3,
                                          right:
                                            expanded !== `panel-${index}`
                                              ? 28
                                              : 18,
                                          display: "flex",
                                          alignItems: "left",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "0.875rem",
                                            fontWeight: 500,
                                          }}
                                        >
                                          <SquareIcon
                                            sx={{
                                              fontSize: "0.85rem",
                                              paddingRight: "2px",
                                              paddingBottom: "2px",
                                            }}
                                            fontSize="small"
                                            className="icon"
                                          />
                                          <span className="bolder-span">
                                            {highlightCount}
                                          </span>
                                          |{" "}
                                          <CircleIcon
                                            sx={{
                                              color: "var(--primaryColor)",
                                              fontSize: "0.85rem",
                                              paddingRight: "2px",
                                              paddingBottom: "2px",
                                              boxSizing: "content-box", // Ensure the padding doesn't affect the overall size
                                            }}
                                            fontSize="small"
                                            className="icon"
                                          />
                                          <span className="bolder-span">
                                            {ideaCardCount}
                                          </span>
                                        </Typography>
                                      </Box>
                                      <Box
                                        className={`chapterTitleBox ${
                                          expanded !== `panel-${index}`
                                            ? `chapterTitleBoxClosed`
                                            : `chapterTitleBox`
                                        } ${
                                          item.entries || item.highlights.length
                                            ? `caret level-${levelCount}`
                                            : `caret-without-content-outer level-${levelCount}  `
                                        }`}
                                      >
                                        <Typography
                                          className={`${
                                            expanded !== `panel-${index}`
                                              ? `responsive-typography`
                                              : ``
                                          } 
                                        `}
                                        >
                                          {item.label || ""}
                                        </Typography>
                                      </Box>
                                    </CustomAccordionSummary>
                                    <CustomAccordionDetails>
                                      <ul className="chapterContents">
                                        <ChaptersLi
                                          key={`chapter-${item._id}`}
                                          id={`chapters-${index}`}
                                        >
                                          {getContentRecursive(
                                            item,
                                            levelCount + 1
                                          )}
                                        </ChaptersLi>
                                      </ul>
                                    </CustomAccordionDetails>
                                  </CustomAccordion>
                                </TilesCardStructureBook>
                              </Grid>
                            ) : null;
                          })
                        : (!tileViewData || tileViewData?.length === 0) && (
                            <CardStructureBook className="listViewParent">
                              <Message
                                message={
                                  <>
                                    Oops! There are no Highlights or Ideacards{" "}
                                    <br />
                                    for this Particular Book
                                  </>
                                }
                                instruction="Highlight your favorite passages and share your insights
                        with us"
                              />
                            </CardStructureBook>
                          )}
                    </Grid>
                  </div>
                </div>
              }
            ></PersistentDrawerRight>
          </>
        ) : viewStatus === ApiStatus.Pending ? (
          <Loading />
        ) : (
          <div title={viewError} className="textDark">
            Loading View Data failed.
          </div>
        )}
      </div>
    </>
  );
}
