import { History } from "./global";

var listener = null;

export const onBackButtonEvent = (navigatePagePath, functionToCall) => {
  return function curried_func(e) {
    e.preventDefault();
    if (functionToCall) {
      functionToCall();
    }
    if (navigatePagePath) {
      History.deepNavigate(navigatePagePath);
    }
    removeBackButtonListener();
  };
};

export function removeBackButtonListener() {
  if (listener) {
    window.removeEventListener("popstate", listener);
    listener = null;
  }
}

export function addBackButtonListener(navigatePagePath, functionToCall) {
  if (!listener) {
    listener = onBackButtonEvent(navigatePagePath, functionToCall);
    window.addEventListener("popstate", listener);
  }
}
