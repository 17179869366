import { useEffect, useState, useRef } from "react";
import { Drawer, Box, IconButton, useMediaQuery } from "@mui/material";
import { ReactComponent as ChatIcon } from "../../../Assets/dinoThing.svg";
import { Chat } from "./Chat";
import { drawerWidth } from "../../Drawer/Drawer";

const SideChat = ({
  showChat,
  open,
  setOpen,
  children,
  chatWidth,
  onWidthChange,
}) => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const resizing = useRef(false);

  // Start resizing when the handle is pressed
  const handleMouseDown = () => {
    resizing.current = true;
  };

  // Update chatWidth based on mouse movement
  const handleMouseMove = (event) => {
    if (resizing.current) {
      const newWidth = window.innerWidth - event.clientX;

      const minWidth = 250;
      const maxWidth = Math.min(window.innerWidth - drawerWidth, 600);

      if (newWidth >= minWidth && newWidth <= maxWidth) {
        onWidthChange(newWidth);
      }
    }
  };

  // Stop resizing when mouse is released
  const handleMouseUp = () => {
    resizing.current = false;
  };

  // Attach and clean up mouse event listeners
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <>
      {showChat ? (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              m: 0,
              transition: "margin 0.3s ease",
              marginRight: open ? `${chatWidth}px` : 0,
              overflowY: "hidden",
              height: "100vh",
            }}
          >
            {children}
          </Box>
          <IconButton
            onClick={() => setOpen(!open)}
            sx={{
              position: "fixed",
              bottom: isMdUp ? 16 : 66,
              right: open && isMdUp ? chatWidth + 16 : !open ? 16 : "auto",
              left: open && !isMdUp ? 16 : "auto",
              zIndex: 1300,
              bgcolor: "var(--primaryColor)",
              color: "white",
              "&:hover": { bgcolor: "var(--primaryColorHover)" },
            }}
          >
            <ChatIcon
              style={{ width: "30px", height: "30px", fill: "currentColor" }}
            />
          </IconButton>
          <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: isMdUp ? chatWidth : "100%",
                boxSizing: "border-box",
              },
            }}
          >
            <Box sx={{ pl: 1.5, pr: 1.5, pt: 0.5 }}>
              <Chat />
              {/* Draggable handle for resizing */}
              {isMdUp && (
                <Box
                  onMouseDown={handleMouseDown}
                  sx={{
                    width: "5px",
                    cursor: "ew-resize",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 1301,
                  }}
                />
              )}
            </Box>
          </Drawer>
        </>
      ) : (
        [children]
      )}
    </>
  );
};

export default SideChat;
