import * as React from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

import IdeaCardPage from "../../pages/IdeacardPage/IdeaCardPage";
import { updatePersistentDrawer } from "../../Utils/Features/persistentDrawerSlice";
import { updateIdentifyIdeaCardData } from "../../Utils/Features/IdentifyIdeaCardSlice";
import { selectIdeaCard } from "../../Utils/Features/librarySlice";

import { store } from "../../Utils/Store/Store";

import { getValueByKey } from "../../helperFunctions/getValueByKey.js";
import { colorConst } from "../../colorConst.js";

export const drawerWidth = 590;
export const closeCrossButtonStyle = {
  borderRadius: "33px",
  position: "absolute",
  top: "9px",
  right: "12px",
  zIndex: 13,
  cursor: "pointer",
  color: "var(--fontColor)",
};
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
    },
  })
);

export const handleDrawerClose = () => {
  // clear identify idea card data (is not always there but does no harm)
  store.dispatch(updateIdentifyIdeaCardData(null));
  // deselect the  ideacard:
  store.dispatch(selectIdeaCard(null));
  // clear idea card data from the drawer / overlay
  store.dispatch(updatePersistentDrawer(null));
};

export default function PersistentDrawerRight({
  childrenx,
  chatOpen,
  chatWidth,
}) {
  const dataType = useSelector((state) => state.persistentDrawerReducer.value);

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dataTypeChangeHandler = () => {
    if (dataType) {
      if (dataType === "ideaCard") {
        return <IdeaCardPage />;
      } else if (dataType === "identify Ideacard") {
        return <IdeaCardPage />;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        columnGap: "5px",
        width: "100%",
      }}
    >
      <Main
        open={dataType}
        sx={{ marginRight: "0px", "&&": { marginRight: "0px !important" } }}
      >
        {childrenx}
      </Main>
      {isMdUp && (
        <Drawer
          sx={{
            width: 573,
            flexShrink: 0,
            position: "fixed",
            right: chatOpen ? chatWidth + 19 : 19,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              background: "transparent",
              border: "none",
              top: "24px",
              right: "auto",
              position: "static",
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!dataType}
        >
          <CloseIcon
            fontSize="medium"
            style={{
              ...closeCrossButtonStyle,
              color: getValueByKey(colorConst, `text-primary-${lightDarkMode}`),
            }}
            onClick={handleDrawerClose}
          />
          {dataTypeChangeHandler()}
        </Drawer>
      )}
    </Box>
  );
}
