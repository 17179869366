import { useEffect, useState } from "react";
import { handleNotAuthorized } from "../../../helperFunctions/global";

export function useChat({
  apiEndpoint,
  startingMessage,
  noTokensLeftMessage,
  useHighlights,
}) {
  const [messages, setMessages] = useState([startingMessage]);
  const [input, setInput] = useState("");

  const handleClearChat = () => {
    setMessages([startingMessage]);
    setInput("");
  };

  useEffect(() => {
    handleClearChat();
    // only want to clear the chat if endpoint changes (for example the book id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint]);

  useEffect(() => {
    if (messages.length === 1) setMessages([startingMessage]);
    // only want to update the starting message if it changes when the chat hasn't started yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingMessage]);

  const sendMessage = async (messageContent) => {
    // Create and add the user message to the current messages.
    const userMessage = { role: "user", content: messageContent };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);

    const sendMessages = updatedMessages.filter(
      (message) => message.role !== "error"
    );

    try {
      // Make the POST request using fetch with JSON body.
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ messages: sendMessages, useHighlights }),
      });

      // handle token expiration
      if (response.status === 401) {
        handleNotAuthorized();
      }

      if (!response.body) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      // Prepare to read the streamed response.
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      // Initialize the assistant message with an empty content.
      let assistantMessage = { role: "assistant", content: "" };
      // Immediately add the assistant message placeholder.
      setMessages((prevMessages) => [...prevMessages, assistantMessage]);

      // Read the stream.
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const chunk = decoder.decode(value, { stream: true });

        let content = chunk;

        const stopMarker = "[DONE]";
        if (chunk.includes(stopMarker)) {
          const cutIndex = chunk.indexOf(stopMarker);
          content = chunk.slice(0, cutIndex);
        }

        const errorMarker = `{"error"`;
        let error = null;
        if (chunk.includes(errorMarker)) {
          const cutIndex = chunk.indexOf(errorMarker);
          if (cutIndex === 0) {
            content = "";
          }
          error = JSON.parse(chunk.slice(cutIndex));
          console.log("Error in AI chat:", error.error);
        }

        const tokenMarker = `{"usedTokens":`;
        let tokens = null;
        if (chunk.includes(tokenMarker)) {
          const cutIndex = chunk.indexOf(tokenMarker);
          if (cutIndex === 0) {
            content = "";
          }
          tokens = JSON.parse(chunk.slice(cutIndex));
        }

        assistantMessage = {
          ...assistantMessage,
          content: assistantMessage.content + content,
        };

        if (tokens) {
          assistantMessage = {
            ...assistantMessage,
            tokens,
          };
        }

        // Update the last message in the state with the new content.
        const updatedAssistantMessage = assistantMessage;
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1] = updatedAssistantMessage;
          if (tokens?.tokensLeft <= 0) {
            newMessages.push(noTokensLeftMessage);
          }
          if (error) {
            newMessages.push({
              role: "error",
              content:
                "Uh-oh! It seems our system is taking a coffee break. We're working to get things percolating again soon. Thanks for your patience!",
            });
          }
          return newMessages;
        });
      }
    } catch (error) {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages.push({
          role: "error",
          content:
            "Oops! Looks like our wires are crossed. We're on it and will be back up shortly. Thanks for your patience!",
        });
        return newMessages;
      });
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!input.trim()) return;
    sendMessage(input);
    setInput("");
  };

  return {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    handleClearChat,
  };
}
