import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Paper,
  List,
  ListItem,
  Avatar,
  Switch,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteSweep from "@mui/icons-material/DeleteSweep";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { ReactComponent as ChatIcon } from "../../../Assets/dinoThing.svg";
import { ProfileImage } from "../../Profile/ProfileImage";

import { getApiUrl } from "../../../helperFunctions/envVars";
import { useChat } from "./useChat";
import { getCurrentBook } from "../../../Utils/Features/librarySlice";

import "./Markdown.css";
import { subscriptionTypes } from "../../Billing/subscriptionTypes";
import { fetchProfile, setTokens } from "../../../Utils/Features/profileSlice";
import { ApiStatus } from "../../../Utils/Features/ApiStatus";

export const Chat = () => {
  const dispatch = useDispatch();
  const book = useSelector((state) => getCurrentBook(state));
  const firstname = useSelector(
    (state) => state?.profileSliceReducer.profileData.firstname
  );
  const status = useSelector(
    (state) => state?.profileSliceReducer.profileStatus
  );
  const tokens = useSelector(
    (state) => state?.profileSliceReducer.profileData.tokensAvailable
  );
  const subscriptionType = useSelector(
    (state) => state?.profileSliceReducer.profileData.subscriptionType
  );
  const [useHighlights, setUseHighlights] = useState(true);

  const [hasTokens, setHasTokens] = useState(tokens > 0);

  const tokensStartingMessage = {
    role: "assistant",
    type: "blockAlert",
    content: `# Hey, Imagination Explorer! 
I am your Book Mentor and Ideacard Generator, here to fire up your creativity and help you explore brilliant ideas!

## Here's How I Can Help:
1. **Craft Inspiring Ideacards:**  
I’ll offer fresh suggestions to bring your ideacards to life.
2. **Dive Into Deep Insights:**  
Whether it's answering your burning questions or exploring intriguing book ideas, I've got you covered.

${
  !book?.read_only
    ? "> **Click „Get highlights“** (available on desktop version) to make sure that you use your full and updated highlights"
    : ""
}

What creative adventure shall we embark on today?`,
  };
  const noTokensStartingMessage = {
    role: "assistant",
    type: "blockAlert",
    content: `# Hello there, Creative Soul!
I am your Book Mentor and Ideacard Generator, here to ignite your imagination!

> ## Unfortunately, it looks like you've used up your tokens for now — no worries!
> > * Your tokens refresh automatically at the start of every month, so you'll be back in action soon.
${
  subscriptionType === subscriptionTypes.Free
    ? "> > * **Why wait till next month?** As a subscribed user, you'll enjoy even more tokens. Check out the Profile/Billing page for details."
    : "> >"
}
> >* If it is a new month and your topup tokens are AWOL, no panic, just refresh the page or logout/login and all will be right again!

Hang tight and keep those ideas brewing — Excited to carry on our adventure once your tokens are topped up!`,
  };

  const [startingMessage, setStartingMessage] = useState(
    hasTokens ? tokensStartingMessage : noTokensStartingMessage
  );

  useEffect(() => {
    if (status === ApiStatus.NotRun || status === null) {
      dispatch(fetchProfile());
    }
  }, [dispatch, status]);

  useEffect(() => {
    setHasTokens(tokens > 0);
    setStartingMessage(
      tokens > 0 ? tokensStartingMessage : noTokensStartingMessage
    );
    // only want to update chat availability status if tokens change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, book]);

  const { messages, input, handleInputChange, handleSubmit, handleClearChat } =
    useChat({
      // TODO: change url according to page if chat will be allowed for other pages too
      apiEndpoint: `${getApiUrl()}/api/ai/bookchat?book_id=${book?._id}`,
      useHighlights,
      startingMessage: startingMessage,
      noTokensLeftMessage: {
        role: "assistant",
        type: "blockAlert",
        content: `# Oops, It Looks Like We’ve Run Out of Tokens!
> It appears that you've used up all your tokens during our chat.
>      
> ## No worries though,
> > * Your token stash refreshes every month, so the creative fun will be back in no time.
${
  subscriptionType === subscriptionTypes.Free
    ? "> > * **Don’t want to wait until next month?** Subscribed users get an extra boost of tokens. Peek at the Profile/Billing page for details."
    : ""
}

Looking forward to picking up our awesome conversation once your tokens are topped up!`,
      },
    });

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
    if (messages.at(-1)?.tokens) {
      dispatch(setTokens(messages.at(-1)?.tokens?.tokensLeft));
      if (messages.at(-1)?.tokens?.tokensLeft <= 0) {
        setHasTokens(false);
      }
    }
  }, [messages, dispatch]);

  const handleCopyMessage = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => console.log("Message copied!"))
      .catch((err) => console.error("Failed to copy message!", err));
  };

  // Function to copy the whole chat (all messages) to clipboard
  const handleCopyAll = () => {
    const allText = messages
      .map(
        (msg) =>
          `${
            msg.role === "assistant"
              ? "ChatBuddy"
              : firstname
              ? firstname
              : "You"
          }: ${msg.content}`
      )
      .join("\n\n");
    navigator.clipboard
      .writeText(allText)
      .then(() => console.log("Entire chat copied!"))
      .catch((err) => console.error("Failed to copy entire chat!", err));
  };

  return (
    <Box display="flex" flexDirection="column" className="chatWrapper">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            ChatBuddy
          </Typography>
          <IconButton onClick={handleClearChat} aria-label="Clear chat">
            <DeleteSweep />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        flexGrow={1}
        overflow="auto"
        ref={scrollContainerRef}
        sx={{
          backgroundColor: "var(--BackgroundColor)",
          borderRadius: "12px",
          marginTop: `10px`,
          p: 1,
        }}
      >
        <List>
          {messages.map((message, idx) => (
            <ListItem
              key={idx}
              sx={{
                p: 0.5,
                pb: 2,
              }}
            >
              <Paper
                sx={{
                  p: 0.5,
                  backgroundColor:
                    message.role === "user"
                      ? "var(--cardBackgroundColor)"
                      : message.role === "assistant"
                      ? "var(--cardHighlightBackgroundColor)"
                      : "var(--alertBackgroundColor)",

                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  className={`chatMarkdown ${
                    message.type === "blockAlert" ? "blockAlert" : ""
                  }`}
                >
                  {message.content}
                </Markdown>
                <Avatar
                  sx={{
                    marginRight: 1,
                    width: 24,
                    height: 24,
                    position: "absolute",
                    bottom: -14,
                    left: 4,
                    backgroundColor: "var(--primaryColor)",
                    color: "white",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "contain", // This scales the image to be fully visible
                    },
                  }}
                >
                  {message.role === "user" ? (
                    <ProfileImage />
                  ) : (
                    <ChatIcon
                      fontSize="small"
                      style={{ fill: "currentColor" }}
                    />
                  )}
                </Avatar>
                {message.tokens && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -14,
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "var(--cardBackgroundColor)",
                      borderRadius: "4px",
                      border: "1px solid var(--borderColors)",
                      padding: "0px 6px",
                    }}
                  >
                    <Typography variant="caption">
                      {message.tokens.tokensLeftPercentage}% of tokens left
                    </Typography>
                  </Box>
                )}
                <IconButton
                  onClick={() => handleCopyMessage(message.content)}
                  sx={{
                    width: 26,
                    height: 26,
                    position: "absolute",
                    bottom: -14,
                    right: 4,
                    backgroundColor: "var(--selectedBackgroundColor)",
                    borderRadius: "8px",
                    padding: "4px",
                    "&:hover": {
                      backgroundColor: "var(--HoverOver)",
                    },
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Paper>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        position="relative"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          p: 2,
          gap: 1,
          paddingTop: 2,
          padding: 0,
        }}
      >
        <IconButton
          onClick={handleCopyAll}
          color="inherit"
          sx={{
            position: "absolute",
            top: -14,
            right: 4,
            backgroundColor: "var(--selectedBackgroundColor)",
            borderRadius: "8px",
            padding: "4px",
            "&:hover": {
              backgroundColor: "var(--HoverOver)",
            },
          }}
        >
          <ContentCopyIcon />
        </IconButton>
        <TextField
          fullWidth
          variant="outlined"
          disabled={!hasTokens}
          placeholder="Type your message..."
          value={input}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(e);
            }
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: 60,
              "&.Mui-focused fieldset": {
                borderColor: "var(--primaryColor)",
                borderWidth: "1px",
              },
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ChapterInfoToggle
            useHighlights={useHighlights}
            setUseHighlights={setUseHighlights}
          />
          <IconButton
            type="submit"
            disabled={!hasTokens}
            sx={{
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
          >
            <SendIcon
              style={{
                color: hasTokens
                  ? "var(--primaryColor)"
                  : "var(--disableColor)",
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const ChapterInfoToggle = ({ useHighlights, setUseHighlights }) => {
  const handleToggle = (event) => {
    setUseHighlights(event.target.checked);
  };

  // Explanation text based on the toggle state
  const tooltipText = useHighlights
    ? "Use highlights: More personalized replies using your chapter & highlight info. (Benefit: tailored conversation; Drawback: uses more tokens)"
    : "No highlights: Replies are more generic, based on AI's general book knowledge. (Benefit: fewer tokens; Drawback: may lack recent or personalized details)";

  return (
    <Tooltip title={tooltipText} arrow>
      <Switch
        size="small"
        checked={useHighlights}
        onChange={handleToggle}
        name="chapterInfoToggle"
        color="primary"
        sx={{
          marginTop: "8px",
          marginLeft: "2px",
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "var(--primaryColor)",
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "var(--primaryColor)",
          },
        }}
      />
    </Tooltip>
  );
};
