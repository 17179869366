import React, { useEffect } from "react";
import {
  Authenticator,
  View,
  Image,
  Text,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import deepreadLogo from "../../Assets/LogoHalfSvg_small.png";

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Deepread logo" src={deepreadLogo} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
};

function AmplifyLoginSignupUI() {
  return (
    <Authenticator components={components}>
      {/* No need to show any content */}
    </Authenticator>
  );
}

export default AmplifyLoginSignupUI;
