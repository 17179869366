import "./App.css";
import "./colorTheme.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";

import MyLibrary from "./pages/MyLibrary/MyLibrary";
import ViewTabMenu from "./pages/ViewTabMenu/ViewTabMenu";
import Sidebar, { menuItems, viewItems } from "./components/Sidebar/Sidebar";

import {
  AuthenticationStatus,
  login,
  subscriptionChecked,
} from "./Utils/Features/authSlice";
import { Loading } from "./components/Loading";
import { customLog } from "./helperFunctions/customLogger";
import ExtensionInstallationPrompt from "./components/Connectors/ExtensionInstallationPrompt";
import AmplifyLoginSignupUI from "./pages/Login/AmplifyLoginSignup";
import MyFeed from "./pages/MyFeed/MyFeed";
import Collection from "./pages/Collection/Collection.jsx";
import { getCurrentBook, selectIdeaCard } from "./Utils/Features/librarySlice";

import { updatePersistentDrawer } from "./Utils/Features/persistentDrawerSlice";
import { updateIdentifyIdeaCardData } from "./Utils/Features/IdentifyIdeaCardSlice";

import {
  fetchIdeacardIcons,
  getAllIdeaIcons,
} from "./helperFunctions/getIdeacardIcons";
import ChromeExtensionConnector from "./components/Connectors/ChromeExtensionCommunication";
import { restartSyncProcess } from "./Utils/Features/amazonSyncSlice";
import Bottombar from "./components/Sidebar/Bottombar";
import Profile from "./components/ProfileTabs/Profile-tabs";
import { History } from "./helperFunctions/global";
import { Logout } from "./components/Logout/Logout";
import { applyColorTheme } from "./helperFunctions/colorThemes.js";
import {
  getCurrentEnvironment,
  isDevelopment,
  isStaging,
} from "./helperFunctions/envVars.js";

// DO not remove this.. needs it for global API error handling
import { axiosInterceptor } from "./helperFunctions/global"; // eslint-disable-line
import toastMessage, { toastType } from "./helperFunctions/toastMessage.js";
import SuccessPage from "./pages/Success/SuccessPage";
import CancelPage from "./pages/Cancel/CancelPage";
import News from "./pages/News/News";
import { hasIntervalPassed } from "./helperFunctions/timing";
import { fetchPostsByCategory } from "./Utils/Features/wordpressSlice.js";
import {
  setNewsLastChecked,
  setSuggestionsLastChecked,
} from "./Utils/Features/userConfigSlice.js";
import { isMobile, isTablet } from "react-device-detect";
import { fetchSuggestionCount } from "./Utils/Features/suggestionSlice.js";
import { SUPPORT_EMAIL } from "./Utils/constants.js";

import SideChat from "./components/Common/Chat/SideChat.jsx";

// configure amplify for multiple redirects
let env = getCurrentEnvironment();

// "redirectSignIn": "http://localhost:3000/,https://app.deepread.com,https://frontend.deepread.com,https://dev.deepread.com,https://deepread.demo1.bytestechnolab.com/",
let [
  localRedirectSignIn, // http://localhost:3000
  prodRedirectSignIn, // https://app.deepread.com
  oldProdRedirectSignIn, // https://frontend.deepread.com
  newStagingRedirectSignIn, // https://dev.deepread.com
  bytesLabIn, // https://deepread.demo1.bytestechnolab.com/
  ngrokIn, // https://louse-credible-monkfish.ngrok-free.app
] = awsconfig.oauth.redirectSignIn.split(",");
let [
  localRedirectSignOut,
  prodRedirectSignOut,
  oldProdRedirectSignOut,
  newStagingRedirectSignOut,
  bytesLabOut,
  ngrokOut,
] = awsconfig.oauth.redirectSignOut.split(",");
customLog(
  `Redirection links: local: ${localRedirectSignIn}, prod: ${prodRedirectSignIn}, NEW staging: ${newStagingRedirectSignIn}, bytesLabIn: ${bytesLabIn}, OLD prod: ${oldProdRedirectSignIn}`
);

let redirectSignIn, redirectSignOut;

switch (env) {
  case "prod":
    redirectSignIn = prodRedirectSignIn;
    redirectSignOut = prodRedirectSignOut;
    break;
  case "staging":
    redirectSignIn = newStagingRedirectSignIn; //stagingRedirectSignIn;
    redirectSignOut = newStagingRedirectSignOut; //stagingRedirectSignOut;
    break;
  case "demo":
    // Add demo redirect links here
    redirectSignIn = bytesLabIn;
    redirectSignOut = bytesLabOut;
    break;
  case "dev":
    //redirectSignIn = ngrokIn;
    //redirectSignOut = ngrokOut;
    redirectSignIn = localRedirectSignIn;
    redirectSignOut = localRedirectSignOut;
    break;
  default:
    redirectSignIn = localRedirectSignIn;
    redirectSignOut = localRedirectSignOut;
    break;
}

// TODO when domain change, add the new newStagingRedirectSignIn/Out to the config
const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn,
    redirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  History.navigate = useNavigate();
  const dispatch = useDispatch();
  const dataType = useSelector((state) => state.persistentDrawerReducer.value);

  const { authenticationStatus, userData } = useSelector((state) => state.auth);

  const { restartSync } = useSelector((state) => state.amazonSync);

  const [, setUser] = useState(null);
  const [, setCustomState] = useState(null);

  // System theme preference  TODO: how do we use this?
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const newsLastChecked = useSelector(
    (state) => state?.userConfig?.newsLastChecked
  );

  const suggestionsLastChecked = useSelector(
    (state) => state?.userConfig?.suggestionsLastChecked
  );

  const { currentPageTab } = useSelector((state) => state?.currentPageTab);

  const chatPaths = ["/views"];
  const currentBook = useSelector((state) => getCurrentBook(state));
  const showChat = chatPaths.includes(window.location.pathname) && currentBook;

  const [chatOpen, setChatOpen] = useState(false);
  const [chatWidth, setChatWidth] = useState(320);

  const newsCheck = () => {
    if (newsLastChecked === null || hasIntervalPassed(newsLastChecked, 24, 0)) {
      dispatch(fetchPostsByCategory("News"));
      dispatch(setNewsLastChecked());
    }
    if (
      authenticationStatus === AuthenticationStatus.Authenticated &&
      (suggestionsLastChecked === null ||
        hasIntervalPassed(suggestionsLastChecked, 24, 0))
    ) {
      dispatch(fetchSuggestionCount());
      dispatch(setSuggestionsLastChecked());
    }
  };

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      newsCheck();
    }
    // we want this effect to run only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      newsCheck();
    }
    // no need to run when newsCheck function is created:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus]);

  // Theme configuration
  const theme =
    lightDarkMode === "dark"
      ? createTheme({
          typography: {
            fontFamily: ["Lato", "sans-serif"].join(","),
            fontColor: "var(--fontColor)",
          },
          palette: {
            mode: "dark",
            background: {
              default: "var(--backgroundBlackFirst)",
            },
          },
          components: {
            MuiInputBase: {
              styleOverrides: {
                input: {
                  color: "var(--white)",
                },
              },
            },
            MuiPopper: {
              styleOverrides: {
                root: {
                  "& .MuiPaper-root": {
                    backgroundColor: "var(--BackgroundColor)",
                    boxShadow: "5px 10px 10px var(--shadowColor)",
                    marginLeft: "10px",
                  },
                },
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  backgroundColor: "var(--backgroundBlackThird)",
                  color: "var(--white)",
                  borderRadius: "8px",
                  padding: "5px 10px",
                  // TODO: fix to use css variables:
                  fontSize: ".875rem",
                  lineHeight: "1.25rem",
                },
                arrow: {
                  color: "var(--backgroundBlackThird)",
                },
              },
            },
            MuiCheckbox: {
              styleOverrides: {
                root: {
                  color: "var(--primaryColor)",
                  "&.Mui-checked": {
                    color: "var(--primaryColor)",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 25 },
                },
              },
            },
            MuiMenu: {
              styleOverrides: {
                list: {
                  border: "1px solid var(--borderColors)",
                  borderRadius: "6px",
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  borderRadius: "12px",
                  boxShadow: "none",
                  borderWidth: "1px",
                  borderColor: "var(--borderColors)",
                  backgroundColor: "var(--cardBackgroundColor)",
                  backgroundImage: "none",
                  color: "var(--fontColor)",
                  gap: "12px",
                  margin: "3px",
                  marginBottom: "20px",
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: "var(--fontColor)",
                },
              },
            },
            MuiAppBar: {
              styleOverrides: {
                root: {
                  backgroundColor: "var(--cardBackgroundColor)",
                  backgroundImage: "none",
                  boxShadow: "none",
                  borderRadius: "12px",
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  borderRadius: "12px",
                },
              },
            },
          },
        })
      : createTheme({
          typography: {
            fontFamily: ["Lato", "sans-serif"].join(","),
            fontColor: "var(--fontColor)",
          },
          palette: {
            mode: "light",
            background: {
              default: "var(--BackgroundColor)",
            },
          },
          components: {
            MuiInputBase: {
              styleOverrides: {
                input: {
                  color: "var(--fontColor)",
                },
              },
            },
            MuiPopper: {
              styleOverrides: {
                root: {
                  "& .MuiPaper-root": {
                    boxShadow: "5px 10px 10px var(--greyColor)",
                    marginLeft: "10px",
                  },
                },
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  backgroundColor: "var(--ClickState)",
                  color: "var(--dark)",
                  fontSize: ".875rem",
                  lineHeight: "1.25rem",
                  borderRadius: "8px",
                  padding: "5px 10px",
                },
                arrow: {
                  color: "var(--ClickState)",
                },
              },
            },
            MuiCheckbox: {
              styleOverrides: {
                root: {
                  color: "var(--primaryColor)",
                  "&.Mui-checked": {
                    color: "var(--primaryColor)",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 25 },
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  borderRadius: "9px",
                  boxShadow: "none",
                  borderWidth: "1px",
                  borderColor: "var(--borderColors)",
                  backgroundColor: "var(--cardBackgroundColor)",
                  backgroundImage: "none",
                  color: "var(--fontColor)",
                  gap: "12px",
                  margin: "3px",
                  marginBottom: "20px",
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: "var(--fontColor)",
                },
              },
            },
            MuiAppBar: {
              styleOverrides: {
                root: {
                  backgroundColor: "var(--cardBackgroundColor)",
                  borderWidth: "1px",
                  borderColor: "var(--borderColors)",
                  backgroundImage: "none",
                  boxShadow: "none",
                  borderRadius: "12px",
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  borderRadius: "12px",
                },
              },
            },
          },
        });

  // update the currentPageTab when using back or forward button in browser:
  useEffect(() => {
    const handlePopState = (event) => {
      const toUrl = window.location.href;
      const urlObject = new URL(toUrl);
      History.storePage(urlObject.pathname);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (lightDarkMode === "light") {
      document.body.style.background = "var(--BackgroundColor)";
    } else if (lightDarkMode === "dark") {
      document.body.style.background = "var(--backgroundBlackFirst)";
    }
    // TODO: eventually we can remove the css color variables from App.css :root
    // when all the css supports this new method of defining and applying them
    // in colorThemes.js
    applyColorTheme(lightDarkMode);
  }, [lightDarkMode]);

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    // Check for the root URL and redirect to /library (in the main case) so we don't start with empty page
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/index.html"
    ) {
      if (restartSync) {
        // in case we left the app for the amazon authentication (federatedSignIn)
        // redirect back to page where amazon sync was initiated before stepping out for authentication
        const views = [...menuItems, ...viewItems];
        const exitView = views.find((view) => view.page === currentPageTab);

        const path = exitView?.path || "/library";
        History.deepNavigate(path, { replace: true });
      } else {
        History.deepNavigate("/feed", { replace: true });
      }
    }
  });

  useEffect(() => {
    if (
      authenticationStatus === AuthenticationStatus.Authenticated &&
      !getAllIdeaIcons()
    ) {
      fetchIdeacardIcons();
    }

    if (
      (isDevelopment() || isStaging()) &&
      authenticationStatus === AuthenticationStatus.Authenticated &&
      (isMobile || isTablet) &&
      userData.isAdmin
    ) {
      const script = document.createElement("script");
      script.src = "https://cdn.jsdelivr.net/npm/eruda";
      script.async = true;
      document.body.appendChild(script);

      // Initialize eruda once the script is loaded
      script.onload = () => {
        if (window.eruda) {
          window.eruda.init();
          window.eruda.position({ x: window.innerWidth - 50, y: 70 });
        }
      };
    }
    // userData.isAdmin stays the same from login to logout.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "signUp":
          setUser(data);
          window.location.reload(); // this seems to do the trick, for manual login, triggers a refresh + redirect
          customLog("signIn || signUp: and RELOAD login: ", event, data);
          break;
        case "signOut":
          setUser(null);
          customLog("signOut - login: ", event, data);
          break;
        case "oAuthSignOut":
          setUser(null);
          customLog("oAuthSignOut: ", event, data);
          break;
        case "customOAuthState":
          setCustomState(data);
          customLog("customOAuthState - login: ", event, data);
          break;
        default:
          customLog("default - login: ", event, data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        customLog("authenticationStatus: ", authenticationStatus);
        if (authenticationStatus === AuthenticationStatus.NotAuthenticated) {
          Auth.currentUserInfo().then((currUserInfo) => {
            customLog("Real User Creation Procedure invoked");
            const body = {
              name: currUserInfo.username,
              customer_id: currUserInfo.username,
              email: currUserInfo.attributes.email,
              nickName: currUserInfo.attributes.nickname,
              givenName: currUserInfo.attributes.given_name,
            };

            dispatch(login({ dispatch, body }));
          });
        } else {
          if (restartSync) {
            dispatch(restartSyncProcess(false));
            if (currentUser.username.includes("amazon")) {
              ChromeExtensionConnector.restartAmazonSync();
            } else {
              ChromeExtensionConnector.showToastError(
                "Amazon authentication failed. Please try again later."
              );
            }
          }
        }
      })
      .catch((error) => customLog("Not signed in: " + error));

    // for the event handling of the amplify login
    return unsubscribe;
    // we want this effect to run only on initial render:
    // [restartSync, dispatch]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerClose = (e) => {
    e?.stopPropagation();
    if (dataType === "ideaCard") {
      dispatch(selectIdeaCard(null));
      dispatch(updatePersistentDrawer(null));
      dispatch(updateIdentifyIdeaCardData(null));
    }
  };

  const checkSubscriptionStatus = (userData) => {
    if (userData?.profile?.trialStarted) {
      toastMessage(
        toastType.INFO,
        false,
        <div className="toast-container">
          🎉 Welcome aboard! 🎉
          <br />
          <br />
          We're excited to have you join us! Your{" "}
          {userData.profile.trialDaysTotal}-day free trial starts now, so dive
          in and enjoy our features.
          <br />
          <br />
          You can subscribe anytime via the Profile/Billing page to keep your
          content private. After {userData.profile.trialDaysTotal} days, you’ll
          switch to the free plan, where all your content will be public.
          <br />
          <br />
          For any questions, our{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>support team</a> is here to help.
          Happy exploring, and welcome to our app family!
          <br />
          <br />
          Best wishes,
          <br />
          The DeepRead Team 🌟
        </div>
      );
    } else if (userData?.profile?.trialEnded) {
      toastMessage(
        toastType.INFO,
        false,
        <div className="toast-container">
          Your {userData.profile.trialDaysTotal}-day free trial has ended, and
          you’re now on the free subscription. We trust you made the most of
          your free trial. ✨
          <br />
          <br />
          You can still use DeepRead, but all your content will be public. To
          keep your content private, subscribe via the Profile &lt; Billing
          page.
          <br />
          <br />
          For questions or assistance, our{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>support team</a> is here for you.
          We hope you continue enjoying the app!
          <br />
          <br />
          Best wishes,
          <br />
          The DeepRead Team 🌟
        </div>
      );
    } else if (userData?.profile?.accountDeleted) {
      toastMessage(
        toastType.INFO,
        false,
        <div className="toast-container">
          Welcome back to DeepRead! ✨
          <br />
          <br />
          Your account has been reinstated. Your content has been deleted when
          you removed your account.
          <br />
          To get your books back, please sync again.
          <br />
          <br />
          For questions or assistance, our{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>support team</a> is here for you.
          We hope you continue enjoying the app!
          <br />
          <br />
          Best wishes,
          <br />
          The DeepRead Team 🌟
        </div>
      );
    }
    dispatch(subscriptionChecked());
  };

  switch (authenticationStatus) {
    case AuthenticationStatus.Pending:
      return <Loading />;
    case AuthenticationStatus.Failed:
      return (
        <Logout>
          <div>
            Hey there!
            <br />
            <br />
            Uh-oh! It looks like there's been a little hiccup with your
            authentication. 😅
            <br />
            <br />
            No sweat, though! Let's try that one more time. If the issue
            persists, just let us know, and we'll lend a hand to sort it out.
            Thanks for your understanding and patience!
            <br />
            <br />
            Best regards,
            <br />
            DeepRead Team
          </div>
        </Logout>
      );
    case AuthenticationStatus.Expired:
      return (
        <Logout>
          <div>
            Hello!
            <br />
            <br />
            Oopsie! It seems your authentication has expired. 🕒
            <br />
            <br />
            Don't worry, it happens! Let's refresh that and get you back on
            track. If you need any assistance along the way, just give us a
            shout, and we'll be right here to help. Thanks for rolling with the
            punches!
            <br />
            <br />
            Warm regards,
            <br />
            DeepRead Team
          </div>
        </Logout>
      );
    case AuthenticationStatus.Authenticated:
      checkSubscriptionStatus(userData);
      return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div
              className="rootContainer"
              onClick={() => {
                if (!chatOpen) {
                  handleDrawerClose();
                }
              }}
            >
              {isMdUp && <Sidebar chatWidth={chatWidth} />}
              <SideChat
                showChat={showChat}
                open={chatOpen}
                setOpen={setChatOpen}
                chatWidth={chatWidth}
                onWidthChange={setChatWidth}
              >
                <Routes>
                  <Route
                    path="/views"
                    element={
                      <ViewTabMenu chatOpen={chatOpen} chatWidth={chatWidth} />
                    }
                  />
                  <Route path="/library" element={<MyLibrary />} />
                  <Route path="/feed" element={<MyFeed />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route path="/cancel" element={<CancelPage />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/notifications" element={<News />} />
                  <Route path="/collection" element={<Collection />} />
                  <Route path="*" element={<> not found</>} />
                </Routes>
              </SideChat>
              {!isMdUp && <Bottombar />}
            </div>
          </ThemeProvider>

          <ToastContainer />

          {/* Extension installation prompt */}
          <ExtensionInstallationPrompt />
        </>
      );
    default:
      // adds layout to the login page, so that it looks more like a DeepRead login ;)
      // eslint-disable-next-line no-lone-blocks
      {
        /* UI for Login */
      }
      return <AmplifyLoginSignupUI />;
  }
}

export default App;
